import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addEditContactDetails',
  props: ['rowLeaseId'],
  data() {
    return {
      leaseContactData: [
        {
          active: null,
          project_type: null,
          module: null,
          prj_name: null,
          contact_purpose: null,
          primary_contact: null,
          contact: null,
          email_type: null,
          contact_type: null
        }
      ],
      leaseContactFields: [
        {
          key: 'selectRadio',
          label: 'Select',
          stickyColumn: true,
          class: 'selectRadio',
          variant: 'primary'
        },
        {
          key: 'project_type',
          label: 'Project Type'
        },
        {
          key: 'prj_name',
          label: 'Project Name'
        },
        {
          key: 'module',
          label: 'Module'
        },
        {
          key: 'active',
          label: 'Active'
        },
        {
          key: 'contact_purpose',
          label: 'Contact Purpose'
        },
        {
          key: 'primary_contact',
          label: 'Primary Contact'
        },
        {
          key: 'contact',
          label: 'Contact'
        },
        {
          key: 'email_type',
          label: 'Email Type'
        },
        {
          key: 'contact_type',
          label: 'Contact Type'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      partyContactId: null,
      contactId: null,
      emailType: null,
      moduleId: null,
      isActive: null,
      index: null,
      contactDetails: {},
      editMode: false,
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: null
    };
  },
  mounted() {
    this.getLeaseProjectContact();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.postLeaseProjectContact();
        }
      }
    });
  },
  methods: {
    addNewRow() {
      this.leaseContactData.push({
        active: null,
        project_type: null,
        module: null,
        prj_name: null,
        contact_purpose: null,
        primary_contact: null,
        contact: null,
        email_type: null,
        contact_type: null
      });
    },
    removeRow(index) {
      this.leaseContactData.splice(index, 1);
    },
    getLeaseProjectContact() {
      const paylaod = {
        prjId: this.rowLeaseId
      };
      this.$store
        .dispatch('leaseProject/getLeaseProjectContact', paylaod)
        .then(response => {
          if (response.status === 200) {
            this.leaseContactData = response.data.data;
            for (let i of this.leaseContactData) {
              this.moduleId = i.module_id;
              this.emailType = i.email_type;
              this.partyContactId = i.party_internal_contact_id;
              this.contactId = i.contact_id;
              this.isActive = i.active;
            }
          }
        })
        .catch(() => {
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedRadio(flag, index, item) {
      if (flag) {
        this.index = index;
        this.contactDetails = {
          party_internal_contact_id: item.party_internal_contact_id,
          prj_id: item.prj_id,
          contact_id: item.contact_id,
          active: item.active,
          email_type: item.email_type,
          module_id: item.module_id
        };
      }
    },
    postLeaseProjectContact() {
      const paylaod = {
        prj_id: this.contactDetails.prj_id,
        active: this.leaseContactData[this.index].active,
        party_internal_contact_id: this.contactDetails
          .party_internal_contact_id,
        contact_id: this.leaseContactData[this.index].contact_id,
        email_type: this.leaseContactData[this.index].email_type,
        module_id: this.contactDetails.module_id
      };
      this.$store
        .dispatch('leaseProject/postLeaseProjectContact', paylaod)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
