import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import AddAddress from '../../../../../admin/address';
import appStrings from '@/app/utility/string.utility';
import codeCombination from '@/app/components/shared/codeCombination';
import addEditContactDetails from './addEditContactDetails'
export default {
  name: 'AddLeaseProject',
  components: {
    DatePicker,
    AddAddress,
    codeCombination,
    addEditContactDetails
  },
  props: ['leasePrjRowDetail'],
  data() {
    return {
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      showValueSetModal: false,
      showAddressModal: false,
      parent_value_set_id: null,
      isDependent: false,
      editMode: false,
      masterPrj: {
        text: null,
        value: null
      },
      sector: {
        text: null,
        value: null
      },
      pocket: {
        text: null,
        value: null
      },
      legalEntity: {
        text: null,
        value: null
      },
      operatingUnit: {
        text: null,
        value: null
      },
      inventoryOrg: {
        text: null,
        value: null
      },
      location: {
        text: null,
        value: null
      },
      address: {
        text: null,
        value: null
      },
      leasePrjType: {
        text: null,
        value: null
      },
      fmsPrj: {
        text: null,
        value: null
      },
      leasePrjCode: null,
      leasePrjName: null,
      leasePrjId: 0,
      start_date_end_date: null,
      startDate: null,
      endDate: null,
      active: true,
      liabilityAcc: null,
      liabilityAccCcid: null,
      liabilityAccMeaning: null,
      debtor: {
        value: null,
        text: null
      },
      segmentFields: [
        {
          key: 'segment_meaning'
        },
        {
          key: 'segment_code'
        }
      ],
      segmentData: [],
      segmentMeaning: '',
      filterSegmentCode: null,
      isBusy: true,
      showConcatSegmentModal: false,
      defaultValue: {
        value: null,
        text: null
      },
      showCodeCombinationModal: false,
      accountType: null,
      combinationDetails: {
        le_id: null,
        project_code: null
      },
      showContatModal: false,
      rowLeaseId:null

      
    };
  },
  mounted() {
    if (this.leasePrjRowDetail) {
      this.fillRecordFromLeasePrjParent(this.leasePrjRowDetail);
     this.rowLeaseId=this.leasePrjRowDetail.lease_project_id;
     
      } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update'&&!this.showContatModal) {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode && !this.showAddressModal ) {
          this.addEditLeaseProject();
        } 
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.leasePrjId });
        }
      }
    });
  },
  methods: {
    addEditLeaseProject() {
      const paylaod = {
        active: this.active,
        address_id: this.address.value,
        start_date: this.startDate,
        end_date: this.endDate,
        le_id: this.legalEntity.value,
        fms_prj_id: this.fmsPrj.value,
        lease_project_code: this.leasePrjCode,
        lease_project_id: this.leasePrjId,
        lease_project_name: this.leasePrjName,
        lease_project_type_vset: this.leasePrjType.value,
        location_id: this.location.value,
        ou_id: this.operatingUnit.value,
        pocket_id: this.pocket.value,
        sector_id: this.sector.value,
        debtor_code: this.debtor.value,
        liability_ccid: this.liabilityAccCcid
      };
      this.$store
        .dispatch('leaseProject/addEditLeaseProject', paylaod)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    fillRecordFromLeasePrjParent(item) {
     this.masterPrj = {
        text: item.master_prj_name,
        value: item.master_prj_id
      };
      this.sector = {
        text: item.sector_name,
        value: item.sector_id
      };
      this.pocket = {
        text: item.pocket_name,
        value: item.pocket_id
      };
      this.legalEntity = {
        text: item.le_name,
        value: item.le_id
      };
      this.operatingUnit = {
        text: item.ou_name,
        value: item.ou_id
      };
      this.inventoryOrg = {
        text: item.inv_org_name,
        value: item.inv_org_id
      };
      this.location = {
        text: item.location_name,
        value: item.location_id
      };
      this.fmsPrj = {
        text: item.fms_prj_name,
        value: item.fms_prj_id
      };
      this.leasePrjType = {
        text: item.lease_project_type,
        value: item.lease_project_type_vset
      };
      this.leasePrjCode = item.lease_project_code;
      this.leasePrjName = item.lease_project_name;
      this.leasePrjId = item.lease_project_id;
      this.startDate = item.start_date;
      this.endDate = item.end_date;
      this.active = item.active;
      this.address = {
        text: item.address_name,
        value: item.address_id
      };
      this.debtor = {
        value: item.debtor_meaning,
        text: item.debtor_code
      };
      this.liabilityAcc = item.liability_ccid_code;
      this.liabilityAccCcid = item.liability_ccid;
      this.liabilityAccMeaning = item.liability_ccid_meaning;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.PRJ_TYPE) {
        this.leasePrjType = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.operatingUnit = this.defaultValue;
        this.inventoryOrg = this.defaultValue;
        this.location = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.inventoryOrg = this.defaultValue;
        this.location = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.INVENTORY_ORG_LIST) {
        this.inventoryOrg = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.location = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.location = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PRJ_MASTER_PROJECT) {
        this.masterPrj = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.sector = this.defaultValue;
        this.pocket = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PRJ_SECTOR) {
        this.sector = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.pocket = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PRJ_POCKET) {
        this.pocket = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.VATIKA_GL_Accounts) {
        this.debtor = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.fmsPrj = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.isDependent = true;
      } else {
        this.isDependent = false;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.INVENTORY_ORG_LIST) {
        this.parent_value_set_id = this.operatingUnit.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.parent_value_set_id = this.inventoryOrg.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PRJ_SECTOR) {
        this.parent_value_set_id = this.masterPrj.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PRJ_POCKET) {
        this.parent_value_set_id = this.sector.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getAddressObj(item) {
      this.address = {
        value: item.address_id,
        text: item.completeAddress
      };
      this.showHideAddressModal(false);
    },
    showHideAddressModal(flag) {
      this.showAddressModal = flag;
    },
    setDate(dateRange) {
      this.start_date_end_date = commonHelper.setFutureDate(dateRange);
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.masterPrj.value) {
        this.masterPrj = this.defaultValue;
      } else if (vsetCode === this.sector.value) {
        this.sector = this.defaultValue;
      } else if (vsetCode === this.pocket.value) {
        this.pocket = this.defaultValue;
      } else if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
        this.operatingUnit = this.defaultValue;
      } else if (vsetCode === this.operatingUnit.value) {
        this.operatingUnit = this.defaultValue;
      } else if (vsetCode === this.inventoryOrg.value) {
        this.inventoryOrg = this.defaultValue;
      } else if (vsetCode === this.location.value) {
        this.location = this.defaultValue;
      } else if (vsetCode === this.leasePrjType.value) {
        this.leasePrjType = this.defaultValue;
      } else if (vsetCode === this.debtor.value) {
        this.debtor = this.defaultValue;
      } else if (vsetCode === this.liabilityAcc) {
        this.liabilityAccCcid = null;
        this.liabilityAcc = null;
        this.liabilityAccMeaning = null;
      } else if (vsetCode === this.fmsPrj.value) {
        this.fmsPrj = this.defaultValue;
      }
    },
    showHideCodeCombinationModal(flag, name) {
      this.accountType = name;
      if (flag) {
        if (!this.legalEntity.value) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        if (!this.leasePrjCode) {
          return alert(appStrings.DEPENDENTVSETMSG.PROJECTMSG);
        }
        this.combinationDetails.le_id = this.legalEntity.value;
        this.combinationDetails.project_code = this.leasePrjCode;
      }
      this.showCodeCombinationModal = flag;
    },

    selectedSegment(item) {
      if (this.accountType === 'liability') {
        this.liabilityAccCcid = item.ccid;
        this.liabilityAcc = item.segment_code;
        this.liabilityAccMeaning = item.segment_meaning;
      }
      this.showHideCodeCombinationModal(false);
    },
    clearSegmentSearchResult() {
      this.segmentMeaning = '';
      this.filterSegmentCode = null;
      this.getConcatSegmentList();
    },
    openContactModal() {
      this.showContatModal = true;
   },
    showHideContactModal(flag) {
      this.showContatModal = flag;
    },
  
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
